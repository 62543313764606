import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "child-themes"
    }}>{`Child Themes`}</h1>
    <div className="alert alert-info">
    <span className="material-icons">get_app</span> Login to <strong><a href="https://themestore.papathemes.com/bigcommerce" target="_blank">PapaThemes Store</a></strong> to download all these child themes for free when you've already purchased Supermarket theme.
    </div>
    <div className="alert alert-warning">
    <span className="material-icons">warning</span> These free child themes has limited techical support. We'll try to answer your questions about this theme, but we're not committed to answer all and fix all bugs of this theme.
    </div>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "child-themes/sima-beauty-theme"
        }}>{`Sima Beauty Theme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "child-themes/sima-furniture-theme"
        }}>{`Sima Furniture Theme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "child-themes/sima-headphones-theme"
        }}>{`Sima Headphones Theme`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      